import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@app/modules/core/config/config';
import { IAppConfig } from '@app/store/models/config.interface';
import { NewAdmin } from '@app/store/models/new-admin.interface';
import { Observable, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { RequestFilters } from '@app/store/models/request-filters.interface';
import { FunctionsService } from '@app/modules/core/services/functions.service';
import { ReferralHistoryRecordInterface } from '@app/store/models/referral-history-record.interface';
import { NewOffTheShelfQrCodesInterface } from '@app/store/models/new-off-the-shelf-qr-codes.interface';
import { AdminUserQrsRequestParameters } from '@app/store/models/admin-userqrs-request-parameters.interface';
import { AdminReferralsRequestParameters } from '@app/store/models/admin-referrals-request-parameters.interface';
import { AdminUserInvoicesRequestParameters } from '@app/store/models/admin-invoices-request-parameters.interface';
import { AdminCheckoutSessionsRequestParameters } from '@app/store/models/admin-checkout-sessions-request-parameters.interface';
import { InvoiceInterface, AppResourceOrganization, AppRole, BatchOperationInterface, ManageBatchOperationOTSQrsRequestValidator, PreparedQrGeneratedRecordInterface, PublicUserDetails, QrTheme, SortDirections, ReferralRecordTypes, Paginated, ManageUserResponse, ManageUserModes, AlaCarteCheckoutSession, MarketingReferralResponse, MarketingLeadsResponse, MarketingWeMissYouResponse, MarketingResponse, EmailAutomationCampaignInterface, EmailAutomationContactInterface } from 'shared-library';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    public emailAutomationCampaigns: EmailAutomationCampaignInterface[] = []
    public emailAutomationCampaignData: Record<string, EmailAutomationContactInterface[]> = {}

    constructor(
        private http: HttpClient,
        private functionService: FunctionsService,
        @Inject(APP_CONFIG) private config: IAppConfig,
    ) { }

    getUserQRs(data: AdminUserQrsRequestParameters): Observable<Paginated<PreparedQrGeneratedRecordInterface>> {
        const data_clone = { ...data }
        if (!data_clone.status) delete data_clone.status
        if (!data_clone.category) delete data_clone.category
        const url = data.qr_id ? `${this.config.apiEndpoints.admin.qrs}/${data.qr_id}` : this.config.apiEndpoints.admin.qrs
        return this.http.get<{ history: Paginated<PreparedQrGeneratedRecordInterface>; success: boolean; }>(url, { params: data_clone as any }).pipe(map(response => response.history));
    }

    getUserInvoices(data: AdminUserInvoicesRequestParameters): Observable<Paginated<InvoiceInterface>> {
        return this.http.get<{ invoices: Paginated<InvoiceInterface>; success: boolean; }>(this.config.apiEndpoints.admin.invoices.list, { params: data as any }).pipe(map(response => response.invoices));
    }

    getReferrals(data: AdminReferralsRequestParameters): Observable<Paginated<ReferralHistoryRecordInterface>> {
        const url = data.record_type === ReferralRecordTypes.CASH_OUT_REQUEST ? this.config.apiEndpoints.admin.referrals.cash_out : this.config.apiEndpoints.admin.referrals.history
        return this.http.get<{ data: Paginated<ReferralHistoryRecordInterface>; success: boolean; }>(url, { params: data as any }).pipe(map(response => response.data));
    }

    getUsers(data: RequestFilters): Observable<Paginated<PublicUserDetails>> {
        return this.http.get<{ data: ManageUserResponse }>(this.config.apiEndpoints.user.basic, { params: data as any }).pipe(map(response => response.data.users));
    }

    getCheckoutSessions(data: AdminCheckoutSessionsRequestParameters): Observable<Paginated<AlaCarteCheckoutSession>> {
        return this.http.get<{ data: Paginated<AlaCarteCheckoutSession> }>(this.config.apiEndpoints.admin.invoices.checkout_sessions, { params: data as any }).pipe(map(response => response.data));
    }

    async getLoginLink(id: string) {
        let { data: response } = await lastValueFrom(this.http.get<{ data: ManageUserResponse }>(`${this.config.apiEndpoints.user.basic}/${id}/${ManageUserModes.SIGN_IN_LINK}`))
        return response.sign_in_link
    }

    getAdmins(data: RequestFilters): Observable<Paginated<PublicUserDetails>> {
        return this.http.get<{ admins: Paginated<PublicUserDetails>; success: boolean; }>(this.config.apiEndpoints.admin.basic, { params: data as any }).pipe(map(response => response.admins));
    }

    createAdmin(data: NewAdmin): Observable<PublicUserDetails> {
        return this.http.post<{ admin: PublicUserDetails; success: boolean; }>(this.config.apiEndpoints.admin.basic, data).pipe(map(response => response.admin));
    }

    getAdminResourceOrganization(): Observable<AppResourceOrganization[]> {
        return this.http.get<{ data: AppResourceOrganization[]; success: boolean; }>(`${this.config.apiEndpoints.resources.admin}organizations`).pipe(map(response => response.data));
    }

    getAdminResourceRoles(): Observable<AppRole[]> {
        return this.http.get<{ data: AppRole[]; success: boolean; }>(`${this.config.apiEndpoints.resources.admin}role`).pipe(map(response => response.data));
    }

    async getOffTheShelfQrCodesBatch(filters = {
        page: 1,
        limit: 100,
        search: "",
        batch_id: "",
        alacarte_product_id: "",
        sort: SortDirections.DESC
    }): Promise<Paginated<BatchOperationInterface>> {
        const { data } = await firstValueFrom(this.http.get<{ data: Paginated<BatchOperationInterface>; }>(this.config.apiEndpoints.admin.batch_operations.off_the_shelf_qr_codes, { params: filters }));
        return data;
    }

    async checkBatchStatus(batch_id: string): Promise<BatchOperationInterface> {
        const { data } = await firstValueFrom(this.http.get<{ data: BatchOperationInterface; }>(`${this.config.apiEndpoints.admin.batch_operations.status_check}${batch_id}`));
        return data;
    }

    async createOffTheShelfBatch(param: NewOffTheShelfQrCodesInterface): Promise<true> {
        const { data } = await firstValueFrom(this.http.post<{ data: true }>(this.config.apiEndpoints.admin.batch_operations.off_the_shelf_qr_codes, param));
        return data;
    }

    async updateOffTheShelfBatchQRCodes(batch_id: string, param: { qr_theme: QrTheme, qr: ManageBatchOperationOTSQrsRequestValidator }): Promise<boolean> {
        const { data } = await firstValueFrom(this.http.patch<{ data: boolean }>(`${this.config.apiEndpoints.admin.batch_operations.update_off_the_shelf_qr_codes}${batch_id}`, param));
        return data;
    }

    async getEmailAutomationCampaigns(): Promise<EmailAutomationCampaignInterface[]> {
        if (this.emailAutomationCampaigns.length) {
            return this.emailAutomationCampaigns
        }
        const { data } = await lastValueFrom(this.http.get<{ data: EmailAutomationCampaignInterface[]; }>(`${this.config.apiEndpoints.resources.admin}email-automations`));
        this.emailAutomationCampaigns = data
        return data;
    }

    async getEmailAutomationCampaignData(campaignId: string, force = false): Promise<EmailAutomationContactInterface[]> {
        if (this.emailAutomationCampaignData[campaignId] && !force) {
            return this.emailAutomationCampaignData[campaignId]
        }
        const { data } = await lastValueFrom(this.http.get<{ data: EmailAutomationContactInterface[]; }>(`${this.config.apiEndpoints.admin.marketing.emailAutomation}manage/${campaignId}`));
        this.emailAutomationCampaignData[campaignId] = data
        return data;
    }

    async emailAutomationRequestSubmission(campaignId: string, param: Array<{ name: string; email: string; }>): Promise<boolean> {
        const { data } = await lastValueFrom(this.http.post<{ data: boolean; }>(`${this.config.apiEndpoints.admin.marketing.emailAutomation}manage/${campaignId}`, param));
        return data;
    }

    async forceSendEmailAutomationRequestSubmission(campaignId: string, scheduleSortKey: string): Promise<boolean> {
        const { data } = await lastValueFrom(this.http.put<{ data: boolean; }>(`${this.config.apiEndpoints.admin.marketing.emailAutomation}manage/${campaignId}`, { scheduleSortKey, action: "send" }));
        return data;
    }

    async cancelEmailAutomationRequest(campaignId: string, scheduleSortKey: string): Promise<boolean> {
        const { data } = await lastValueFrom(this.http.put<{ data: boolean; }>(`${this.config.apiEndpoints.admin.marketing.emailAutomation}manage/${campaignId}`, { scheduleSortKey, action: "cancel" }));
        return data;
    }


}
