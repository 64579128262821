import { InjectionToken } from "@angular/core";
import { QrDownloadFileTypes } from "@app/store/enums/qr-download-file-types.enum";
import { IAppConfig } from "@app/store/models/config.interface";
import { ManageBatchOperationRequestModes, ManageCheckoutSessionRequestModes } from "shared-library";
import { environment } from 'src/environments/environment';


export const APP_CONFIG = new InjectionToken("config");
export const AppConfig: IAppConfig = {
  apiEndpoints: {
    admin: {
      basic: `${environment.api_endpoint}admins`,
      qrs: `${environment.api_endpoint}admin/qrs`,
      coupons: `${environment.api_endpoint}admin/coupons/`,
      products: `${environment.api_endpoint}admin/products/`,
      marketing: {
        emailAutomation: `${environment.api_endpoint}admin/marketing/email-automation/`,
      },
      invoices: {
        list: `${environment.api_endpoint}admin/invoices/list`,
        checkout_sessions: `${environment.api_endpoint}admin/checkout/${ManageCheckoutSessionRequestModes.SESSIONS}`,
      },
      referrals: {
        history: `${environment.api_endpoint}admin/referral/history`,
        cash_out: `${environment.api_endpoint}admin/referral/cash-out`,
      },
      prices: `${environment.api_endpoint}admin/products/prices/`,
      support_tickets: `${environment.api_endpoint}admin/support/tickets`,
      batch_operations: {
        status_check: `${environment.api_endpoint}admin/batch-operations/${ManageBatchOperationRequestModes.STATUS_CHECK}/`,
        off_the_shelf_qr_codes: `${environment.api_endpoint}admin/batch-operations/${ManageBatchOperationRequestModes.OFF_THE_SHELF}`,
        download_off_the_shelf_qr_codes: `${environment.api_endpoint}admin/batch-operations/${ManageBatchOperationRequestModes.DOWNLOAD_OFF_THE_SHELF}/`,
        off_the_shelf_qr_codes_batch: `${environment.api_endpoint}admin/batch-operations/${ManageBatchOperationRequestModes.OFF_THE_SHELF_BATCH_DETAILS}/`,
        update_off_the_shelf_qr_codes: `${environment.api_endpoint}admin/batch-operations/${ManageBatchOperationRequestModes.UPDATE_OFF_THE_SHELF_QR_CODES}/`,
      },
    },
    user: {
      basic: `${environment.api_endpoint}users`,
      upload: `${environment.api_endpoint}upload/`,
      qrs: `${environment.api_endpoint}user/{id}/qrs`,
      support: `${environment.api_endpoint}support/ticket`,
      popups: `${environment.api_endpoint}user/{id}/popups`,
      api_keys: `${environment.api_endpoint}user/{id}/api-keys`,
      qr_stats: `${environment.api_endpoint}user/{id}/qrs/stats`,
      pricings: `${environment.api_endpoint}user/{id}/pricings/`,
      qr_themes: `${environment.api_endpoint}user/{id}/qr-themes`,
      referral: {
        paypal: `${environment.api_endpoint}user/{id}/referral/paypal`,
        invite: `${environment.api_endpoint}user/{id}/referral/invite`,
        history: `${environment.api_endpoint}user/{id}/referral/history`,
        cash_out: `${environment.api_endpoint}user/{id}/referral/cash-out`,
      },
      tags: {
        qrs: `${environment.api_endpoint}user/{id}/resources/qrs/tags`,
        popups: `${environment.api_endpoint}user/{id}/resources/popups/tags`
      },
      batch_operations: {
        status_check: `${environment.api_endpoint}user/{id}/batch-operations/${ManageBatchOperationRequestModes.STATUS_CHECK}/`,
        off_the_shelf_qr_codes: `${environment.api_endpoint}user/{id}/batch-operations/${ManageBatchOperationRequestModes.OFF_THE_SHELF}`,
        download_off_the_shelf_qr_codes: `${environment.api_endpoint}user/{id}/batch-operations/${ManageBatchOperationRequestModes.DOWNLOAD_OFF_THE_SHELF}/`,
        off_the_shelf_qr_codes_batch: `${environment.api_endpoint}user/{id}/batch-operations/${ManageBatchOperationRequestModes.OFF_THE_SHELF_BATCH_DETAILS}/`,
      },
      resources: `${environment.api_endpoint}user/{id}/resources/type/`,
      qr_responses: `${environment.api_endpoint}user/{id}/qrs/responses/`,
      popups_responses: `${environment.api_endpoint}user/{id}/popups/responses/`,
      payments: {
        invoices: `${environment.api_endpoint}user/{id}/history/invoices`,
        checkout: `${environment.api_endpoint}user/{id}/payments/checkout`,
        ala_carte: `${environment.api_endpoint}payments/alacarte/`,
        validate_coupons: `${environment.api_endpoint}user/{id}/payments/coupons/`,
        redeem_voucher: `${environment.api_endpoint}user/{id}/payments/redemption/`,
        download_invoice: `${environment.api_endpoint}user/{id}/history/download-invoice`,
        manage_payment_methods: `${environment.api_endpoint}user/{id}/payments/methods/card/`,
        manage_subscription: `${environment.api_endpoint}user/{id}/payments/subscriptions/manage/`,
      },
      workspaces: {
        list: `${environment.api_endpoint}user/{id}/workspaces`,
      }
    },
    resources: {
      log: `${environment.api_endpoint}log/`,
      pricings: `${environment.api_endpoint}pricings`,
      ala_carte: `${environment.api_endpoint}alacarte`,
      upload: `${environment.api_endpoint}public/upload/`,
      crawl_link: `${environment.api_endpoint}crawl/link`,
      newsletter: `${environment.api_endpoint}newsletter/`,
      admin: `${environment.api_endpoint}admin/resources/type/`,
      public: `${environment.api_endpoint}public/resources/type/`,
      user: `${environment.api_endpoint}user/{id}/resources/type/`,
    },
    qrs: {
      data: `${environment.api_endpoint}qrs/profiles/`,
      respond: `${environment.api_endpoint}qrs/response/`,
    },
    popups: {
      data: `${environment.api_endpoint}popups/`,
    },
    auth: {
      signin: `${environment.api_endpoint}auth/signin`,
      signup: `${environment.api_endpoint}auth/signup`,
      session_data: `${environment.api_endpoint}auth/token`,
      resetpassword: `${environment.api_endpoint}auth/forgot-password`,
      signup_by_invitation: `${environment.api_endpoint}auth/signup/invite/`,
    },
  },
  dummy_qr_code: `${environment.app_url_full}assets/qr-is-scannable.jpeg`,
  allowed_image_types: [QrDownloadFileTypes.JPG, QrDownloadFileTypes.JPEG, QrDownloadFileTypes.PNG],
  allowed_video_types: ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm'],
  max_file_upload_size: 5, //In mb this.files[0].size/1024/1024
  max_desktop_upload_size: 10, //In mb this.files[0].size/1024/1024
  max_request_payload_size: 6, //In 6mb
  max_icon_size: 2, //In 2mb
  noticeMessages: {
    featureUpgrade: "This feature is not available in your current plan, please upgrade to a different plan to use this feature.",
    enterpriseFeature: "This feature is exclusively available for enterprise users. For inquiries or details, please contact our support team. Thank you.",
  },
};

